button.main-button.mdc-button {
  background-color: #8A4FFF;
  box-shadow: 0px 4px 0px 0px #2B0080, 0px 6px 4px 0px #AF71FF inset, 0px -4px 4px 0px #FFFFFF33 inset, 4px 0px 4px 0px #FFFFFF33 inset, -4px 0px 4px 0px #FFFFFF33 inset;
  height: 40px;

  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: center;
  z-index: 0;

  font-family: "Saira Semi Condensed";

  --mdc-text-button-label-text-color: white;
}

button.main-button.mdc-button:hover {
  background-color: #6f3ae1;
}

button.main-button.green.mdc-button {
  background-color: #1BD671;
  box-shadow: 0px 4px 0px 0px #0e8344, 0px 6px 4px 0px #43ef92 inset, 0px -4px 4px 0px #FFFFFF33 inset, 4px 0px 4px 0px #FFFFFF33 inset, -4px 0px 4px 0px #FFFFFF33 inset;
}

button.main-button.green.mdc-button:hover {
  background-color: #16c667;
}

button.main-button.mdc-button span {
  z-index: 1;
  text-shadow: 0px 2px 4px 0px #000000A3;
}

button.main-button:disabled {
  color: #FCFCFC;
  opacity: 0.6;
  filter: grayscale(0.1) brightness(0.7);
}

.main-button:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.064) 0%, rgba(0, 0, 0, 0.32) 52.6%, rgba(0, 0, 0, 0.064) 100%);
}

.mdc-button-toggle-appearance-standard {
  color: #FCFCFC;
  background: black;
  font-family: "Saira Semi Condensed";
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;

}

button.mat-button-toggle-button {
  width: 84px;
}

mat-button-toggle-group {
  background-clip: padding-box; /* !importanté */
  border: solid 2px transparent; /* !importanté */
}

mat-button-toggle-group:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px; /* !importanté */
  border-radius: inherit; /* !importanté */
  background: linear-gradient(to right, red, orange);
}


.mat-form-field-appearance-outline .mat-form-field-outline {
  border: 2.5px solid #8A4FFF66;
  border-radius: 6px;
  background-color: black;
}

.mat-select-arrow {
  color: #8A4FFF;
}

.mat-select-value {
  color: #BB99FF;
}

.mdc-notched-outline {
  background-color: black;
  border-radius: 6px;
}


.cdk-overlay-dark-backdrop {
  backdrop-filter: blur(8px);
}

/*mat-dialog-container .mdc-dialog__container {
  //width: 1000px;
  //height: 778px;
  background: linear-gradient(0deg, rgba(67, 59, 161, 0.56), rgba(67, 59, 161, 1));
  border-radius: 8px;
  border: 2px solid #433BA1;
  backdrop-filter: blur(12px);
}*/

.mat-mdc-dialog-container .mdc-dialog__container {
  overflow-y: hidden;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  height: auto !important;
  min-height: 100% !important;
  overflow-x: hidden;
}


.mdc-dialog__container {
  display: block !important;
}

.cdk-overlay-pane mat-dialog-container {
  border-radius: 8px;
  border: 2px solid #433BA1;
  background: linear-gradient(0deg, rgba(67, 59, 161, 0.56), rgba(67, 59, 161, 1));
  backdrop-filter: blur(28px);
  overflow-y: hidden;
  color: unset;
}

.large-modal {
  width: 95%;
  height: auto;
  max-width: 1000px !important;
  max-height: 80vh;
}

.small-modal {
  width: 95%;
  height: auto;
  max-width: 464px !important;
  max-height: 80vh;
}

.sign-transact-modal {
  width: 92%;
  height: auto;
  max-width: 906px !important;
  max-height: 80vh;
}

.mat-mdc-dialog-container
{
  --mdc-dialog-container-color: transparent;
  --mdc-dialog-container-elevation: unset;
}

mat-form-field.simple-select .mat-mdc-text-field-wrapper .mdc-notched-outline {
  background-color: transparent;
  border: 2px solid rgba(138, 79, 255, 0.5);
  color: #BB99FF;
  border-radius: 6px;
}

mat-form-field.simple-select .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  display: none;
}

mat-form-field.simple-select .mat-mdc-text-field-wrapper.mdc-text-field {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
}

.cdk-overlay-pane .simple-select-panel {
  background-color: #1B142A !important;
}

.cdk-overlay-pane .simple-select-panel .mat-mdc-option{
  color: rgba(252, 252, 252, 0.8);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
  border: solid 2px var(--mat-standard-button-toggle-divider-color) !important;
}

mat-form-field.simple-select .mat-mdc-text-field-wrapper.mdc-text-field {
  height: 52px;
}

mat-button-toggle-group.flex-width mat-button-toggle {
  width: 100%;
}

mat-button-toggle-group.flex-width mat-button-toggle button.mat-button-toggle-button {
  width: 100% !important;
}

mat-button-toggle button.mat-button-toggle-button {
  width: 92px;
}


:root {
  --mat-standard-button-toggle-selected-state-background-color: #BE8AFF;
  --mat-standard-button-toggle-selected-state-text-color: black;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: black;
  --mat-select-enabled-trigger-text-color: #BE8AFF;
  --mat-select-placeholder-text-color: #BE8AFF;
  --mat-select-enabled-arrow-color: #BE8AFF;
  --mat-standard-button-toggle-divider-color: #492B8C;
}

