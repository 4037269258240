@use '@angular/material' as mat;
@include mat.core();

$accent:  mat.define-palette((
  50 : #ffede0,
  100 : #ffd1b3,
  200 : #ffb380,
  300 : #ff954d,
  400 : #ff7e26,
  500 : #8A4FFF,
  600 : #ff5f00,
  700 : #ff5400,
  800 : #ff4a00,
  900 : #ff3900,
  A100 : #ffffff,
  A200 : #fff4f2,
  A400 : #ffcabf,
  A700 : #ffb4a6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
));

$primary: mat.define-palette((
  50 : #f7f1ff,
  100 : #ecdcff,
  200 : #dfc5ff,
  300 : #d2adff,
  400 : #c89cff,
  500 : #be8aff,
  600 : #b882ff,
  700 : #af77ff,
  800 : #a76dff,
  900 : #995aff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #fefeff,
  A700 : #eee5ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )));

$warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);


$primary-color: mat.get-color-from-palette($primary, 500);
$primary-color-dark: #011F3E;
$secondary-color:  mat.get-color-from-palette($accent, 500);
$warn-color:  mat.get-color-from-palette($warn, 500);
$dark-color: #414141;
$gray-color: #929FAD;

.primary-color {
  color: $primary-color;
}

.bg-color-primary {
  background-color: $primary-color;
}

.bg-color-primary-dark {
  background-color: $primary-color-dark !important;
}

.accent-color {
  color: $secondary-color;
}

.bg-color-accent {
  background-color: $secondary-color;
}

.darkLightText {
  color: $gray-color;
}

.darkText {
  color: $dark-color;
}

input::placeholder,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: white;
  opacity: 1;
}
