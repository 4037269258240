
#toasta.toasta-position-top-right {
  top: 100px !important;
}

#toasta .toast .toast-text {
  padding: 5px 20px 5px 20px;
}

#toasta .toast .close-button {
  top: 50%;
  transform: translateY(-50%);
  min-width: 32px;
  min-height: 32px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#toasta .toast .close-button:after {
  font-size: 19px !important;
}

#toasta .toast .durationbackground {
  height: 2px !important;
}

#toasta .toast .durationbackground .durationbar {
  background-color: #FCFCFC;
}

#toasta .toast {
  cursor: unset;
}

#toasta .toast .toast-text {
  font-family: "Saira Semi Condensed", Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0.04em;
  font-weight: 500;
}


#toasta {

  .toast {
    border-radius: 8px 2px 2px 8px !important;
    /*background-image: unset !important;
    background-color: unset !important;
    background-image: linear-gradient(180deg, rgba(23, 48, 138, 0.88) 0%, rgba(3, 1, 81, 0.88) 100%);*/

    border-width: 1px 10px 1px 1px;
    border-style: solid !important;
    border-color: #7F8287 !important;

    color: #FCFCFC !important;
    overflow: hidden;
  }

  .toast:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-image: linear-gradient(180deg, rgb(0, 0, 0) 0%, #36373c 100%);
  }

  .toast.toasta-type-success {
    border-color: #1EE384 !important;
  }
  .toast.toasta-type-success .close-button:after {
    color: #1EE384;
  }

  .toast.toasta-type-success:before {
    background-image: linear-gradient(180deg, rgb(0, 0, 0) 0%, #133c1d 100%);
  }

  .toast.toasta-type-error {
    border-color: #E31E41 !important;
  }
  .toast.toasta-type-error .close-button:after {
    color: #E31E41;
  }

  .toast.toasta-type-error:before {
    background-image: linear-gradient(180deg, rgb(0, 0, 0) 0%, #3d1615 100%);
  }

  .toast-text,
  .toast-msg {
    z-index: 1 !important;
    position: relative;
  }

  .close-button {
    z-index: 2 !important;
    position: relative;
  }

}
