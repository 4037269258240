.retry-popup-dialog .mat-dialog-container {
  box-shadow: unset;
  background: unset;
  color: #FCFCFC;
}

.modalToolbar {
  background: #00000033;
  width: 100%;
  padding: 12px 20px;
  font-family: "Saira Semi Condensed";
  font-size: 20px;
  font-weight: 300;
  line-height: 31px;
  letter-spacing: 0.01em;
  text-align: left;
  border-radius: 8px 8px 0 0;

}

.modalContent {
  padding: 32px 24px;
  font-family: "Saira Semi Condensed";
  height: 700px;
  position: relative;
}

.close-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.modal-actions-zone {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: rgba(138, 79, 255, 0.4);
  height: 56px;
  padding: 0 42px;
}

.modal-actions-zone .main-button {
  min-width: 132px;
}

.modal-actions-zone .main-button {
  min-width: 132px;
  font-size: 16px !important;
}

.button {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.005em;
  text-align: center;
}


.character-icon-zone {
  position: relative;
  height: 276px;
  width: 100%;
  max-width: 276px;
  background: linear-gradient(180deg, #003ACF 0%, #5081FF 0.01%, rgba(54, 79, 142, 0) 100%);
  /*border-image-source: linear-gradient(90deg, #003ACF 0%, #5081FF 0.01%, rgba(54, 79, 142, 0) 100%, rgba(80, 129, 255, 0) 100%);*/
}

.level-tag {
  background: url("/assets/images/level-bg.png") no-repeat center center / contain;
  position: absolute;
  width: 88px;
  height: 36px;
  top: 12px;
  padding-top: 2px;
  font-size: 10px;
  line-height: 14px;
  left: 0;
}

.level-tag .label {
  font-size: 10px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
}
.level-tag .value {
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
}

.button-import {
  height: 56px;
  color: white;
  background: #00000066;
}

.button-stake {
  height: 56px;
  color: white;

  outline: 1.5px solid #7955c1;
  /*border: 1.5px solid #0000001A;
  border-radius: 4px;*/
  background: linear-gradient(180deg, #8A4FFF 0%, #4C2B8C 100%);
  box-shadow: 0px -4px 4px 0px #FFFFFF1F inset, 4px 0px 4px 0px #FFFFFF1F inset, -4px 0px 4px 0px #FFFFFF1F inset;
}

.button:hover {
  opacity: 0.8;
}

.info-header {
  width: 100%;
  background: #8098C61A;
  padding: 4px 12px;
  border-radius: 4px 24px 0px 0px;
  gap: 10px;
}

.asset-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #B2CDFF;

}

.asset-mint {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #B2CDFF7A;

}

.stats-zone {
  padding: 16px 20px;
  background: rgba(128, 152, 198, 0.1);
  height: 100%;
}

.stats-zone .column-stats {
  flex: 1 1 270px;




  min-height: 200px;
}

.card-title {
  font-size: 16px;
  font-weight: 300;
  color: #A7A5C6;
}

.radius-card {
  padding: 16px 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.32);
}

.stat-name {
  font-size: 16px;
  font-weight: 300;
  color: #FCFCFC;
}

.value-stat {
  font-weight: 600;
  font-size: 16px;
  color: #1BD671;
}

.value-stat.negative {
  color: #FF1A1A;
}


.chart-zone {
  padding-left: 24px;
}

.stats-zone div:not(.progress){
  width: 100%;
}

.stats-info {
  gap: 8px;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.005em;
}

.stats-info strong {
  font-weight: 600;
}


.progress-bar {
  background: rgba(0, 0, 0, 0.32);
  height: 16px;
  border-radius: 2px;
  position: relative;
}

.progress {
  background: linear-gradient(90deg, #8098C6 0%, #BBD2FF 100%);
  height: 100%;
  border-radius: 2px;
}


.assets-zone {
  gap: 4px;
  width: 100%;
  height: 100%;
}

.asset-header {
  width: 100%;
  border-radius: 16px 16px 0 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.01em;
  gap: 4px;
  background: rgba(0, 0, 0, 0.2);
  padding: 4px 20px;

}

.asset-count {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: rgba(252, 252, 252, 0.64);
}

.assets-content {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.no-assets {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-align: center;
  height: 100%;
}





.no-assets .link {
  font-weight: 700;
  color: rgb(58, 218, 103);
  cursor: pointer;
}

.modal-link {
  font-weight: 700;
  color: rgba(138, 79, 255, 1);
  cursor: pointer;
}
