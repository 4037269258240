@import "./app/core/styles/material.scss";
@import "./app/core/styles/modals.css";
@import "./app/core/styles/toast.scss";
@import url('https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "./app/core/styles/utils.css";
@import "./app/core/styles/animations.css";
@import '../node_modules/ngx-toasta/styles/style-default.css';

html, body { height: 100%; }
body {
  margin: 0;
  font-family: "Saira Semi Condensed", Roboto, sans-serif;
  //overflow: hidden;
  background: #000000;
  min-width: 320px;
  color: white;
}

* {
  box-sizing: border-box;
}

app-login-eos .popup-container {
  background: rgba(58, 50, 42, 0.88) !important;
  color: #fff;
  border: 3px solid rgba(92, 75, 60, 0.8);
  outline: 6px solid rgba(58, 50, 42, 0.88);
  display: flex;
  flex-direction: column;
  font-family: 'creme', sans-serif;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

app-login-eos .popup-close-icon {
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  background: url('/assets/bg_close.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  padding-top: 5px;
  position: absolute;
  top: -18px;
  right: -18px;
  font-size: 32px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.asdflj {
  color: #000000A6;
}

app-login-eos .popup-close-icon:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  color: #ffffff;
}

app-login-eos .login-wallet.login-wombat {
  order: 2;
}

app-login-eos .popup-header {
  justify-content: center;
  margin-bottom: 0 !important;
}

app-login-eos .popup-header-text {
  color: #3A322A !important;
  transform: translateY(-46px);
  font-size: 24px !important;
  padding: 20px 28px !important;
  background: url('/assets/bg-title.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
app-login-eos .login-wallet-header {
  color: #3A322A !important;
  font-size: 22px !important;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}
app-login-eos .login-wallet-text {
  color: #3A322A !important;
  font-size: 18px !important;
  font-weight: 300;
  letter-spacing: 0.04em;
}

app-login-eos .login-wallet {
  border: unset !important;
  //border-radius: 10px;
  background: url('/assets/bg_item_login.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

app-login-eos .login-wallet:hover {
  background: url('/assets/bg_item_login.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

app-login-eos .login-eostock {
  margin-top: -24px !important;
}

app-login-eos .login-eostock .login-wallet-logo,
app-login-eos .login-wallet:nth-of-type(5) .login-wallet-logo {
  height: 44px !important;
  width: 44px !important;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: 0 !important;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

app-login-eos .login-eostock .login-wallet-logo {
  //content:url("/assets/wcw-logo.png");
  background-image: url('/assets/wcw-logo.png');
}

app-login-eos .login-wallet:nth-of-type(5) .login-wallet-logo  {
  //content:url("/assets/anchor-logo.png");
  background-image: url('/assets/anchor-logo.png');
}

app-login-eos .login-wallet-logo img {
  display: none;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.prevent-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}


.content {
  width: 100%;
  padding: 32px 16px;
}

.characters-rows {
  width: 100%;
  gap: 20px;
}

.main-card {
  height: 310px;
  width: 240px;
  background: linear-gradient(180deg, rgba(23, 48, 138, 0.88) 0%, rgba(3, 1, 81, 0.88) 100%);
  border: 2px solid #433BA1;
  position: relative;
}

.card-shade {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(67, 59, 161, 0) 43%, #2E278A 67.53%, #171255 97%);
}

.info-zone {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 12px 12px 12px;
  text-align: center;
  z-index: 1;
}

.main-card .main-button {
  margin-top: 14px;
  height: 40px;
}

.card-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}

.main-card small {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #947DC0;
  text-transform: uppercase;
}

.main-background {
  position: fixed;
  z-index: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("/assets/images/bg-main.jpeg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.main-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding-top: 80px;
}

.main-background:before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  top: 0;
  background-image: url("/assets/images/texture.png");
  background-size: cover;
  background-position: center;
  opacity: 0.2;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(252, 252, 252, 0.4);
  border: none;
  border-radius: 4px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome */
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.container-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 0.9rem;
}


@media screen and (max-height: 600px) { /*//(orientation: landscape)
//@media only screen and (max-width: 767px), only screen and (max-device-width: 767px) {
//@media screen and (orientation:landscape)
//and (min-device-width: 319px)
//and (max-device-width: 600px) {*/

  .anchor-link-qr {
    max-width: 100px;
  }
  .anchor-link-subtitle {
    margin: 2px 0 0 0 !important;

  }
  .anchor-link-title {
    margin-top: -6px !important;
    font-size: 20px !important;
    line-height: 24px !important;
  }
  .anchor-link-request {
    padding: 12px !important;
    border-radius: 16px !important;
  }
  .anchor-link-footnote {
    display: none;
  }
  .anchor-link-logo {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@media screen and (max-height: 310px) {
  .anchor-link-qr {
    display: none;
  }
  .anchor-link-subtitle {
    text-indent: -9999px;
    line-height: 0 !important; /* Collapse the original line */
  }
  .anchor-link-subtitle::after {
    content: 'Rotate your device to scan the QR-code or click the button';
    text-indent: 0;
    display: block;
    line-height: initial; /* New content takes up original line height */
  }
}

@media screen and (max-width: 600px) {
  app-login-eos .popup-container  {
    width: 78% !important;
  }
  .no-max-width-on-mobile {
    max-width: unset !important;
  }
  .flex-grow-on-mobile {
    flex-grow: 1 !important;
  }
  .container-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 460px) {
  .container-cards {
    grid-template-columns: 1fr;
  }
}
