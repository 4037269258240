.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.m1 {
  margin: 8px;
}

.m2 {
  margin: 16px;
}

.m3 {
  margin: 24px;
}

.m4 {
  margin: 32px;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: 8px;
}

.mt2 {
  margin-top: 16px;
}

.mt3 {
  margin-top: 24px;
}

.mt4 {
  margin-top: 32px;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb1 {
  margin-bottom: 8px;
}

.mb2 {
  margin-bottom: 16px;
}

.mb3 {
  margin-bottom: 24px;
}

.mb4 {
  margin-bottom: 32px;
}

.ml1 {
  margin-left: 8px;
}

.ml2 {
  margin-left: 16px;
}

.ml3 {
  margin-left: 24px;
}

.ml4 {
  margin-left: 32px;
}

.mr1 {
  margin-right: 8px;
}
.mr2 {
  margin-right: 16px;
}

.mr3 {
  margin-right: 24px;
}

.mr4 {
  margin-right: 32px !important;
}

.pt1 {
  padding-top: 8px;
}

.pt2 {
  padding-top: 16px;
}

.pt3 {
  padding-top: 24px;
}

.pt4 {
  padding-top: 32px;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: 8px;
}

.pb2 {
  padding-bottom: 16px;
}

.pb3 {
  padding-bottom: 24px;
}

.pb4 {
  padding-bottom: 32px;
}

.pl1 {
  padding-left: 8px;
}

.pl2 {
  padding-left: 16px;
}

.pl3 {
  padding-left: 24px;
}

.pl4 {
  padding-left: 32px;
}

.pr1 {
  padding-right: 8px;
}

.pr2 {
  padding-right: 16px;
}

.pr3 {
  padding-right: 24px;
}

.pr4 {
  padding-right: 32px;
}

.p1 {
  padding: 8px;
}

.p2 {
  padding: 16px;
}

.p3 {
  padding: 24px;
}

.p4 {
  padding: 32px;
}

.row {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.offset-10 {
  margin-left: 10%;
}

.offset-20 {
  margin-left: 20%;
}

.leftText {
  text-align: left;
}

.centerText {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

.bold-text {
  font-weight: 800 !important;
}

.flex-50 {
  flex: 1 1 50%;
}

.object-contain {
  object-fit: contain;
}

.pointer {
  cursor: pointer;
}
